@use "../../styles/mixins.scss" as *;

.footer {
  @include flex(center, flex-end);
  color: whitesmoke;
  background-image: url("../../assets/images/waves2.svg");
  background-repeat: repeat-x;
  height: 150px;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  &__copyright {
    margin-bottom: 0.5rem;
  }
}
