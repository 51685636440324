@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(19, 24, 44, 0.9);
  z-index: 100;

  @include tablet {
    overflow: scroll;
  }
}

.modal {
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow: scroll;

  @include flex(space-between, normal, column);
  position: absolute;
  top: 0;
  left: 0;

  background-color: whitesmoke;

  @include tablet {
    width: 75%;
    height: auto;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    padding: 2rem;
  }

  @include desktop {
    width: 80%;
    max-width: 90rem;
    top: 10%;
    padding: 1rem 3rem;
  }

  &__button-container { 
    @include flex(center,center);
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__button {
    display: block;
    font-family: Roboto Mono, sans-serif;
    width: 16.25rem;
    overflow: hidden;
    padding: 0.5rem;
    background-color: whitesmoke;
    box-shadow: $box-shadow;
    transition: 0.5s ease-in-out;
    cursor: pointer; 

    &--current { 
      background-color: #555555;
      color: whitesmoke;
      border: 2px solid black;
      box-shadow: none
    }
  }
  
  &__container {
    @include flex(normal, normal, column);
  }

  &__exit-container {
    @include flex(flex-end);

    @include tablet { 
      margin-bottom: 1rem;
    }
  }
  &__text {
    @include tablet {
      margin: 0 3rem;
    }
  }
  &__title {
    margin-bottom: 0.5rem;
  }
}
.exit {
  cursor: pointer;
}


