@use "../../styles/mixins.scss" as *;

.coming-soon {
  @include flex(center, center);
  height: 100vh;
  margin: 1rem 0;

  @include tablet {
    padding: 10rem 5rem;
    height: 100%;
  }

  background-color: #555555;
  &__title {
    color: whitesmoke;
  }
}
