@use "../../styles/mixins.scss" as *;

.search {
  @include flex(normal, center);
  gap: 0.5rem;
  width: 100%;
  background-color: whitesmoke;
  border: 1px solid #555555;
  height: 40px;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;

  @include tablet { 
    width: 49%;
  }
  @include desktop { 
    width: 24%;
  }
  &__icon {
    width: 20px;
  }
  &__input {
    font-family: "Roboto Mono", sans-serif;
    border: none;
    height: 100%;
    width: 100%;
    padding: 0.2rem;
    background-color: whitesmoke;
    &:focus-visible {
      outline: none;
    }
  }
}
