@use "../styles/mixins.scss" as *;

.heading {
  font-size: 1.5rem;
  line-height: 2rem;

  @include tablet {
    font-size: 2.0625rem;
    line-height: 2.75rem;
  }
}

.section-heading {
  font-size: 1.2rem;
  line-height: 1.7rem;

  @include desktop {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.sub-heading {
  font-size: 1rem;
  line-height: 1.125rem;

  @include tablet {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}
.body {
  font-size: 0.875rem;
  line-height: 1.125rem;

  @include tablet {
    line-height: 1.375rem;
  }
}

.card-body { 
  font-size: .9375rem;
  line-height: 1.375rem;

  @include tablet { 
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
.button {
  font-family: "Roboto Mono", sans-serif;
  font-size: 14px;
  @include tablet {
    font-size: 15px;
    line-height: 1.25rem;
  }
}
.link {
  font-size: 0.75rem;
  line-height: 1.25rem;
}
