@use "../../styles/mixins.scss" as *;

.main-locations {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
.locations {
  @include flex(normal, normal, column);
  gap: 0.5rem;

  @include tablet { 
    @include flex(flex-start);
    flex-wrap: wrap;
    row-gap: 1rem;
  }
}

.card--locations {
    @include tablet { 
        width: 49%;
    } 
    @include desktop { 
        width: 24%;
    }
}