.field {
    display: flex;
    flex-direction: column;

    &__label {
        margin-bottom: 0.5rem;
        font-size: 15px;
    }

    &__input {
        margin-bottom: 1rem;
        border: 1px solid #ccc;
        padding: 0.5rem;
        font-size: 15px;
        font-family: "Roboto Mono", sans-serif;
    }
}
