@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

.login-page {
  min-height: 75vh;
  @include flex(center, center, column);
  padding: 1rem;
  flex-grow:1;
}

.login {
  @include flex(normal, normal, column);
  width: 22rem;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  padding: 2rem 1.5rem;
  margin-bottom: 1rem;

  &__title {
    margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 1.5rem;
    border-bottom: 5px solid #555555;
    padding-bottom: 0.5rem;
    align-self: center;
  }

  &__button {
    margin-top: 1rem;
    color: black;
    padding: 0.5rem;
    border: 1px solid #555555;
    background-color: whitesmoke;
    box-shadow: $box-shadow;
    font-size: inherit;
    cursor: pointer;
    transition: background-color 0.15s;

    &:hover {
      box-shadow: $box-shadow-hover;
    }
  }

  &__link {
    color: blue;
  }

  &__message {
    border-left: 5px solid #555555;
    padding: 0.75rem;
    margin-top: 2rem;
  }
}
