@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

.home__title {
  color: whitesmoke;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-right: 0.7rem;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid #555555;
}

.carousel {
  position: relative;
  margin-bottom: 2rem;
  &__overlay {
    @include flex(center, center, column);
    gap:0.5rem;
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;

    @include tablet { 
      width: 90%;
    }
  }
  &__btn { 
    color: #555555;
    background-color: whitesmoke;
    box-shadow: $box-shadow;
    font-family: Roboto Mono, sans-serif;
    padding: 0.5rem 1rem;
    &:hover {
      box-shadow: $box-shadow-hover;
    }
  }
}
