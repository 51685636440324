@use "../../styles/mixins.scss" as *;

.header {
  @include flex(space-between, center);
  padding: 1.5rem 1rem;
  @include tablet { 
    padding: 1.5rem 2rem;
  }
  @media screen and (min-width: 1930px) {
    padding: 1.5rem 5rem;
  }

  &__logo {
    font-size: 16px;
    font-weight: 400;
    color: whitesmoke;
  }
  &__nav {
    @include flex();
  }
  &__link {
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    position: relative;

    &.active {
      background-color: #555555;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      &::before {
        content: "";
        top: -22px;
        left: 0px;
        position: absolute;
        border-top-width: 10px;
        border-bottom-width: 12px;
        border-left-width: 15px;
        border-right-width: 15px;
        border-style: solid;
        border-color: transparent transparent whitesmoke;
       
      }
      &::after {
        content: "";
        top: -19px;
        left: 10px;
        position: absolute;
        border-top-width: 10px;
        border-bottom-width: 10px;
        border-left-width: 13px;
        border-right-width: 13px;
        border-style: solid;
        border-color: transparent transparent #666666;
        
      }
    }
  }
}
