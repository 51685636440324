@use "../../styles/mixins.scss" as *;
@use "../../styles/animations.scss" as *;
@use "../../styles/variables.scss" as *;

.card {
  position: relative;
  width: 100%;
  height: 100%;
  &__contrast {
    @include flex(space-between, center, column);
    padding: 3rem 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 2;
    color: white;
    animation: background-out 1s forwards linear;
  }
  &__heading {
    font-weight: 400;
    text-align: center;
    height: 60px;
  }
  &__background {
    width: 100%;
    height: 100%;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__btn-container {
    @include flex(center, center, column);
    gap: 0.5rem;
  }
  &__btn {
    position: relative;
    display: block;
    font-family: Roboto Mono, sans-serif;
    width: 16.25rem;
    overflow: hidden;
    padding: 0.5rem;
    background-color: whitesmoke;
    box-shadow: $box-shadow;
    transition: 1s ease-in-out;
    z-index: 1;
    cursor: pointer;
    &--remove {
      background-color: #555555;
      color: white;
      box-shadow: none;
    }
    &--animate { 
      &:before { 
        position: absolute;
        height: 100%;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: -50px;
        border-right: 50px solid transparent;
        border-bottom: 80px solid #555555;
        transition: 1s ease-in-out;
        transform: translateX(-100%);
        z-index: -1;
      }
      &:hover {
        color: white;
      }
      &:hover:before{ 
        transform:translateX(0)
      }
    }
  }
  &--locations {
    height: 25rem;
  }
  &__icon-container {
    @include flex(space-between, center);
    background-color: rgba(245, 245, 245, 0.7);
    padding: 0.5rem;
    width: 16.25rem;
    height: 4.6875rem;
  }
  &__icon {
    width: 4.375rem;
    &--danger {
      width: 2.5rem;
      fill: #245eac;
    }
  }
  &__wind {
    color: black;
  }
  &__temp {
    color: black;
  }
}
