@use "../../styles/mixins.scss" as *;

.hero {
  position: relative;
  margin-bottom: 1rem;
  height: 100vh;
  width: 100%;
  background: url("../../assets/images/hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  @include desktop {
    background-position: unset;
  }
  &__text {
    font-size: 12px;
    width: 100%;
    height: 100%;
    padding: 2rem;
    @include flex(center, center, column);
    color: whitesmoke;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &__title {
    width: 100%;
  }
  &__p {
    width: 100%;
  }
}
