@use "./mixins.scss" as *;

#root { 
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

body {
  min-height: 100vh;
  font-family: "Roboto Mono", sans-serif;
  background: linear-gradient(
    to bottom,
    #181818,
    #2b2b2b,
    #404040,
    #555555,
    #6c6c6c,
    #7f7f7f,
    #939393,
    #a7a7a7,
    #bcbcbc,
    #d2d2d2,
    #e8e8e8,
    #ffffff
  );
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a { 
  text-decoration: none;
  color: whitesmoke;
  cursor: pointer;
}

.mantine-1v3yzoy, .mantine-1jkjqkp {
  border-radius: 0;
}

.main { 
  min-width: 100%;
  margin: 0 auto;
}

.content-block { 
  margin: 0 1rem;

  @include tablet { 
    margin: 0 1.5rem;
  }
  @include desktop { 
    margin: 0 3rem;
  }
}
